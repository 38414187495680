<template>
  <v-container fluid class="primary" style="overflow-y: scroll">
    <v-row class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="">
          <v-card-title>Content Management System</v-card-title>
          <v-card-subtitle
            >Administrators and Corporate Users may add, edit and delete panels
            of content shown on the dashboard. CMS archives (snapshots) may be
            <router-link to="/snapshots"> viewed here</router-link
            >.</v-card-subtitle
          >
          <v-card-text>
            <v-row>
              <v-col class="col col-3">
                <label for="">Which panel would you like to edit?</label>
                <v-select
                  v-model="panel"
                  :items="cms"
                  item-text="name"
                  item-value="_id"
                ></v-select
              ></v-col>
              <v-col class="col col-3">
                <v-btn
                  class="my-6"
                  color="indigo"
                  text
                  block
                  @click="initAddCMS()"
                >
                  Add a new panel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="showEditor" class="ml-6">
      <v-col class="col col-10">
        <v-card elevation="1" class="ml-6 mb-12">
          <v-card-title>Editor</v-card-title>
          <v-card-text>
            <quill-editor
              ref="myQuillEditor"
              v-model="content"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="col col-2">
        <v-card elevation="1" class="mb-12">
          <v-card-title>Options</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="name"
                :rules="[rules.required]"
                label="Name of Panel"
                required
              ></v-text-field>
              <label for="width">Width</label>
              <v-radio-group v-model="width">
                <v-radio label="Full Width" :value="12"></v-radio>
                <v-radio label="Half Width" :value="6"></v-radio>
                <v-radio label="Third Width" :value="4"></v-radio>
                <v-radio label="Quarter Width" :value="3"></v-radio>
              </v-radio-group>

              <label for="visibleRole">Visibility</label>
              <v-checkbox
                v-model="visibleRole"
                label="All"
                value="All"
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="visibleRole"
                v-for="role in roles"
                v-bind:key="role"
                :label="role"
                :value="role"
                hide-details
              ></v-checkbox>
              <v-text-field
                class="my-6"
                v-model="sort"
                label="Sort Order"
                hint="Lower numbers appear first"
              ></v-text-field>
            </v-form>
            <v-btn
              class="my-6"
              color="blue lighten-2"
              outlined
              block
              @click="saveCMS()"
            >
              Save
            </v-btn>
            <v-btn
              v-if="role === 'admin'"
              class="my-6"
              color="red lighten-2"
              outlined
              block
              @click="showConfirm = true"
            >
              Delete
            </v-btn>
            <div v-if="showConfirm">
              Are you sure?
              <v-btn
                class="my-6"
                color="red lighten-2"
                text
                block
                @click="deleteCMS()"
              >
                Yes
              </v-btn>
              <v-btn
                class="my-6"
                color="blue lighten-2"
                text
                block
                @click="showConfirm = false"
              >
                Nevermind
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
export default {
  name: "CMS",
  components: {
    quillEditor
  },
  data: () => ({
    // content: "",
    valid: true,
    rules: {
      required: value => !!value || "Required."
    },
    _id: "",
    name: "",
    panel: "",
    width: 12,
    widths: [12, 6, 4, 3],
    visibleRole: ["All"],
    users: [],
    showConfirm: false,
    showEditor: false,
    content: "",
    sort: 0,
    editMode: "add"
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    },
    cms() {
      return this.$store.state.cms;
    },
    // content: {
    //   get: function () {
    //     return this.$store.state.cms.dashboard;
    //   },
    //   set: function (newValue) {
    //     this.$store.commit("updateDashboard", {
    //       dashboard: newValue,
    //     });
    //   },
    // },
    roles() {
      let users = this.users;
      const unique = [...new Set(users.map(item => item.role))];
      return unique;
    }
  },
  methods: {
    initAddCMS() {
      this._id = "";
      this.showEditor = true;
      this.editMode = "add";
      this.name = "";
      this.panel = "";
      this.width = 12;
      this.visibleRole = ["All"];
      this.content = "";
      this.sort = 0;
      this.showConfirm = false;
    },
    onEditorBlur(quill) {
      // console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      // console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      // console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      // console.log('editor change!', quill, html, text)
      this.content = html;
      this.$store.commit("updateDashboard", {
        dashboard: html
      });
    },
    saveCMS() {
      let vm = this;
      if (vm.editMode === "add") {
        let cms = {
          name: this.name,
          width: this.width,
          roles: this.visibleRole,
          cms: this.content,
          sort: this.sort
        };
        axios
          .post(vm.$root.urls.api + "/cms", {
            cms
          })
          .then(function(results) {
            // toast
            vm.$toasted.show("CMS panel added").goAway(2500);
            vm.$store.commit("addSingleCMS", {
              cms: results.data
            });
          });
      } else {
        let cms = {
          _id: this._id,
          name: this.name,
          width: this.width,
          roles: this.visibleRole,
          cms: this.content,
          sort: this.sort
        };
        axios
          .put(vm.$root.urls.api + "/cms", {
            cms
          })
          .then(function(results) {
            // toast
            vm.$toasted.show("CMS updated").goAway(2500);
            vm.$store.commit("updateSingleCMS", {
              cms
            });
          });
      }
    },
    deleteCMS() {
      let vm = this;
      // console.log("gonna delete an existing panel");
      let cms = {
        _id: this._id
      };
      axios.defaults.headers.common = {
        Authorization: `Bearer ${vm.token}`
      };
      axios
        .delete(vm.$root.urls.api + "/cms", {
          data: {
            cms
          }
        })
        .then(function(results) {
          vm.$store.commit("deleteSingleCMS", {
            cms
          });
          // toast
          vm.$toasted.show("CMS Panel deleted").goAway(2500);
          vm.initAddCMS();
        });
    }
  },
  mounted: function() {
    let vm = this;
    // query api for users
    axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
    axios.get(vm.$root.urls.api + "/users", {}).then(function(results) {
      vm.users = results.data;
    });
  },
  watch: {
    panel(val) {
      if (val) {
        let panel = this.cms.filter(c => c._id === val);
        this._id = panel[0]._id;
        this.name = panel[0].name;
        this.width = panel[0].width;
        this.visibleRole = panel[0].roles;
        this.content = panel[0].cms;
        this.sort = panel[0].sort;
        this.editMode = "edit";
        this.showEditor = true;
      }
    }
  }
};
</script>

<style lang="scss">
.ql-snow .ql-editor h2 {
  font-family: "GothamExLight", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}
.ql-editor p {
  margin-bottom: 16px;
}
.ql-editor {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}
</style>
